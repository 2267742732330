@tailwind base;
@tailwind components;
@tailwind utilities;


  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #C7CAEF;
  }

@font-face {
  font-family: "Mulish";
  src: url("../public/fonts/Mulish-ExtraLight.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "Mulish";
  src: url("../public/fonts/Mulish-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Mulish";
  src: url("../public/fonts/Mulish-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Mulish";
  src: url("../public/fonts/Mulish-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Mulish";
  src: url("../public/fonts/Mulish-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Mulish";
  src: url("../public/fonts/Mulish-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Mulish";
  src: url("../public/fonts/Mulish-ExtraBold.ttf");
  font-weight: 800;
}
@font-face {
  font-family: "Mulish";
  src: url("../public/fonts/Mulish-Black.ttf");
  font-weight: 900;
}
@font-face {
  font-family: "Mulish";
  src: url("../public/fonts/Mulish-ExtraLightItalic.ttf");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Mulish";
  src: url("../public/fonts/Mulish-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Mulish";
  src: url("../public/fonts/Mulish-Italic.ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Mulish";
  src: url("../public/fonts/Mulish-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Mulish";
  src: url("../public/fonts/Mulish-SemiBoldItalic.ttf");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Mulish";
  src: url("../public/fonts/Mulish-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Mulish";
  src: url("../public/fonts/Mulish-ExtraBoldItalic.ttf");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Mulish";
  src: url("../public/fonts/Mulish-BlackItalic.ttf");
  font-weight: 900;
  font-style: italic;
}


* {
  box-sizing: border-box;
  @apply font-content;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:focus-visible {
  outline: none;
}

input,
select {
  @apply outline-none;
  box-sizing: border-box;
  display: inline-block;
}

.center {
  justify-content: center;
}

.opacity-all *{
  @apply bg-opacity-50 text-gray-600 text-opacity-50;
}

@layer base {
  h1 {
    @apply text-3xl font-heading text-violet;
  }
  h2 {
    @apply text-base font-content text-violet;
  }
  h3 {
    @apply text-sm font-content font-semibold text-violet;
  }
  p {
    @apply text-base  text-input;
  }
  sup {
    vertical-align: super;
  }
  sub {
    vertical-align: sub;
  }
  }

  input:disabled {
    -webkit-appearance: none;
    opacity:1;
 }


  #eJjeZKLvcH03 {
    transform-box: fill-box;
    transform-origin: 50% 50%;
    -webkit-animation: loadspin 3.5s infinite cubic-bezier(.35, 1.24, .99, .92);
    animation: loadspin 3.5s infinite cubic-bezier(.35, 1.24, .99, .92);

  }

  @-webkit-keyframes loadspin {
    0% {
      -webkit-transform: rotate3d(0,0,1,0deg);
    }

    100% {
      -webkit-transform: rotate3d(0,0,1,720deg);
    }
  }

  @keyframes loadspin {
    0% {
      transform: rotate3d(0,0,1,0deg);
    }

    100% {
      transform: rotate3d(0,0,1,720deg);
    }
  }