.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
  display: block;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
 
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
  background: #B4BCCE;
}



.react-switch-label.normal {
  width: 34px;
  height: 16px;
}
.react-switch-label.large {
  width: 44px;
  height: 24px;
}
.react-switch-button.normal {
  width: 20px;
  height: 20px;
  top: -2px;
  left: -2px;
}
.react-switch-button.large {
  width: 28px;
  height: 28px;
  top: -2px;
  left: -4px;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;  
  border-radius: 40px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  border-width: 1px;
  border-color: #B4BCCE;
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% + 1px);
  transform: translateX(-100%);
}

.react-switch-checkbox:checked + .react-switch-label {
  background: #6648DC;
}

.react-switch-label:active .react-switch-button {
  width: 24px;
}