form > label {
  display: block;
  padding-top: 8px;
}

form > label:first-child {
  padding-top: 0;
}

form > input {
  display: block;
  margin-bottom: 8px;
}
